import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import abductionIllustration from '../images/abduction-illustration.svg';

const browser = typeof window !== 'undefined' && window;

function NotFoundPage() {
    return !browser ? null : (
        <Layout>
            <SEO title="404: Not found" />
            <div>
                <img
                    src={abductionIllustration}
                    className="block mx-auto w-1/2"
                    alt="Ghost getting abducted by aliens"
                />
                <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
                    Looks like this page is a ghost that got abducted by aliens...
                </h2>
            </div>
        </Layout>
    );
}

export default NotFoundPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
